import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"
import "./about.scss"
import "../fragments/image"
import FormattedText from "../components/FormattedText"

export default function About({
  data: {
    strapiAbout: { seo, title, titleBg, companySection, companyVision },
  },
}) {
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname="about"
      />
      <section className="section is-medium" id="about-hero">
        <div className="container">
          <h1 className="title is-1">{title}</h1>
        </div>
        <SiteImage image={titleBg} className="hero-bg" alt="" />
      </section>
      <section className="section" id="company">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <SiteImage
                image={companySection.companyLogo}
                className="pr-6"
                alt="company logo"
              />
              <div className="has-text-white my-6">
                <FormattedText>
                  {companySection.companyDescription}
                </FormattedText>
              </div>
            </div>
            <div className="column is-5 is-flex is-align-items-end">
              <SiteImage image={companySection.sectionBackground} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="company-vision">
        <div className="container">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <div className="section-title">{companyVision.title}</div>
          </div>
          <div className="columns">
            {companyVision.visionItem.map(
              ({ id, title, description }, index) => {
                const classIndex = (index % 3) + 1
                return (
                  <div className="column is-4" key={id}>
                    <div className="is-flex is-align-items-end my-3">
                      <div
                        className={`title-decoration vision-item-decoration-${classIndex} mr-3`}
                      />
                      <div className={`title vision-item-title-${classIndex}`}>
                        {title}
                      </div>
                    </div>
                    <div className="my-3">
                      <FormattedText>{description}</FormattedText>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiAbout {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      title
      titleBg {
        ...StrapiImage
      }
      companySection {
        companyDescription
        companyLogo {
          ...StrapiImage
        }
        sectionBackground {
          ...StrapiImage
        }
      }
      companyVision {
        title
        visionItem {
          id
          title
          description
        }
      }
    }
  }
`
